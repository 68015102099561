




































import {Component, Vue, Prop, VModel} from 'vue-property-decorator';

// needs to be a minimum of 5. Should be an odd number
const MAXSHORTCUTS = 7;
const FIRSTPAGE = 1;

interface ShortCut {
  id: string;
  value: string;
}

@Component({})
export default class PaginationComponent extends Vue {
  /**
   * The current page
   */
  @VModel({required: true, type: Number})
  public page!: number;
  /**
   * The amount of items that should be shown in the table. Used to calculate the maxPage
   */
  @Prop({default: 0, type: Number})
  public itemCount!: number;
  /**
   * The string value, representing the trunked shortcuts
   */
  @Prop({default: '...', type: String})
  public trunkString!: string;
  /**
   * The values to select, how many items should be displayed per page
   * @private
   */
  private paginationSelect: number[] = [10, 25, 50, 100];
  /**
   * The value how many items should be displayed per page. Default is the first value of the paginationSelect
   * @private
   */
  private perPage: number = this.paginationSelect[1];

  private get maxPage() {
    const maxPage = Math.ceil(this.itemCount / this.perPage) + (FIRSTPAGE - 1);
    return maxPage > (FIRSTPAGE - 1) ? maxPage : FIRSTPAGE;
  }

  private get pageShortcuts() {
    const shortCuts: ShortCut[] = [];
    for (let i = FIRSTPAGE; i < this.maxPage + 1; i++) {
      shortCuts.push({value: i + '', id: '' + i});
    }
    if (shortCuts.length > MAXSHORTCUTS) {
      const half = Math.floor((MAXSHORTCUTS - 2) / 2); // These -2 are the first and the last shortcut
      let leftSide = this.page - half;
      let rightSide = this.page + half;
      if (leftSide - FIRSTPAGE <= 0) {
        rightSide += Math.abs(leftSide - FIRSTPAGE) + 1;
      } else if ((shortCuts.length - 1) - rightSide < 0) {
        leftSide -= Math.abs((shortCuts.length - 1) - rightSide);
      }
      const spliceRight = ((shortCuts.length - 1) - rightSide) + 1;
      // begin splicing at the end
      if (spliceRight > 1) {
        shortCuts.splice(rightSide - 1, spliceRight, {value: this.trunkString, id: 'sr'});
      }
      const spliceLeft = leftSide - FIRSTPAGE;
      if (spliceLeft > 1) {
        shortCuts.splice(FIRSTPAGE, spliceLeft, {value: this.trunkString, id: 'sl'});
      }
    }
    return shortCuts;
  }

  public mounted() {
    this.changePerPage();
  }

  private changePage(count: number = 0) {
    const page = this.page + count;
    this.checkPage(page);
  }

  private checkPage(page: number = this.page) {
    // if reload browser, the maxPage getter returns 0 because of itemCount is 0 at this time. To prevent page become 0
    // check for maxPage > 0
    if (page > this.maxPage) {
      this.page = this.maxPage;
    } else if (page < FIRSTPAGE) {
      this.page = FIRSTPAGE;
    } else {
      this.page = page;
    }
  }

  private changePerPage() {
    this.checkPage();
    this.$emit('changePerPage', this.perPage);
  }

  private get menuProps() {
    return {
      closeOnClick: false,
      closeOnContentClick: false,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 304,
      offsetY: true,
      bottom: true,
      rounded: 'lg',
      offsetOverflow: true,
      nudgeBottom: 10,
      contentClass: 'custom-form-select-menu',
    };
  }
}
